/*
<!-- Begin Mailchimp Signup Form -->
<link href="//cdn-images.mailchimp.com/embedcode/slim-10_7.css" rel="stylesheet" type="text/css">
<style type="text/css">
	#mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; }
	/* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
	   We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. 
       </style>
       <div id="mc_embed_signup">
       <form action="https://gmail.us20.list-manage.com/subscribe/post?u=5b028e842c525b72708e68e09&amp;id=b79a511468" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
           <div id="mc_embed_signup_scroll">
           <label for="mce-EMAIL">Subscribe to our mailing list</label>
           <input type="email" value="" name="EMAIL" class="email" id="mce-EMAIL" placeholder="email address" required>
           <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
           <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_5b028e842c525b72708e68e09_b79a511468" tabindex="-1" value=""></div>
           <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
           </div>
       </form>
       </div>
       
       <!--End mc_embed_signup-->
*/

import React from "react"

class MailChimp extends React.Component {

    constructor(props){
        super(props);
    }

    render() {
        return (
            <div>
                <form 
                    action="https://gmail.us20.list-manage.com/subscribe/post?u=5b028e842c525b72708e68e09&amp;id=b79a511468"
                    method="post"
                >
                <div className="row">
                    <label for="mce-EMAIL">Follow us to get the latest posts</label>
                </div>
                <div className="row">
                    
                    <formset>
                    <input type="text" defaultValue="" name="EMAIL" id="mce-email" placeholder="email address" required />
                    <div className="hidden"><input type="text" name="b_5b028e842c525b72708e68e09_b79a511468" tabindex="-1" value="" /></div>
                    <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button"/>
                    </formset>
                    
                </div>
                </form>
                
            </div>
        )
    }
}

export default MailChimp