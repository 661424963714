import React from "react"
import {Link} from "gatsby"

class PopularPosts extends React.Component {

    constructor(props){
        super(props);
    }

    render() {
        const popPosts = [
            <li>
            <Link to="/artificial-intelligence-airports">
                Artificial intelligence and the airport
            </Link>
            </li>,
            <li>
            <Link to="/regional-airport-retreats-armidale">
                Explore the New England region of Australia from Armidale
            </Link>
            </li>,
            <li>
            <Link to="/secrets-of-global-flying-jetlag">
                How to survive jetlag
            </Link>
            </li>,
            <li>
                <Link to="daylight-savings-time-confusion">
                    End daylight savings time
                </Link>
            </li>,
            <li>
                <Link to="/eight-long-haul-flying-tips">
                    8 tips for long haul flying
                </Link>
            </li>,
            <li>
            <Link to="/what-is-a-fun-sized-airport">
                Fun-sized airport flying
            </Link>
        </li>,
        ].sort(function() { return 0.3 - Math.random() });
        const latestDeals = [
            <li>
                <Link to="/fun-sized-flying-from-albury-london-may-2019">
                    Albury to London for AU $1,332
                </Link>
            </li>,
            <li>
                <Link to="fun-sized-flying-from-mildura-april-2019">
                    Mildura to Bali for AU $993
                </Link>
            </li>,
            <li>
                <Link to="hidden-city-holiday-syd-iah-den">
                    Save $90 on Sydney to Houston flight by exploring Denver
                </Link>
            </li>,
            <li>
            <Link to="fun-sized-flying-from-newcastle-delhi-may-2019">
                    Newcastle to New Delhi for little over AU $1,000
            </Link>
        </li>,
        <li>
            <Link to="fun-sized-flying-from-ballina-april-2019">
                    Ballina to Fiji for $840
            </Link>
        </li>,
        ]
        return (
            <div>
                <h4>
                    
                    Popular posts
                    
                </h4>
                
                <ul>
                    
                    {popPosts}
                    
                </ul>

                <h4>
                    Latest deals
                </h4>
                <ul>
                    {latestDeals}
                </ul>
                
            </div>
        )
    }
}

export default PopularPosts