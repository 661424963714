import React from "react"
import { graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AboutShort from "../components/aboutshort"
import MailChimp from "../components/mailchimp"
import PopularPosts from "../components/popularposts"

class About extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="About" />
        <div className="w-full">
          <div className="text-center px-6 py-12 mb-6 bg-gray-100 border-b">
            <h1 className=" text-xl md:text-4xl pb-4">About Landing Last Minute</h1>
          </div>
        </div>
        <div className="simple-post">
          <div className="simple-post container max-w-5xl mx-auto py-8">
              <AboutShort />
              <h2>Contact</h2>
              <p>Want to get in touch? Send an email to{' '} 
                <a href="mailto:contact@landinglastminute.com">contact@landinglastminute.com</a>.</p>
              <Bio />
          </div>
        </div>
        
      </Layout>
    )
  }
}

export default About

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`